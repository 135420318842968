@import '../../mixins/respond';
@import '../../mixins/font';
@import '../../shared/settings';

.buying-options-banner {
    display: flex;
    margin: 0 0 54px;
    border: 1px solid $light-grey;
    border-radius: 10px;

    > div {
        padding: 34px;
        width: 100%;
        min-width: calc(50% - 70px);
        display: flex;
        flex-direction: column;
    }

    .social-banner-content {
        flex-grow: 1;
    }

    h5 {
        margin: 0;
        line-height: 34px;
        font-size: 24px;
        font-weight: bold;
    }

    p {
        flex-grow: 1;
        margin-top: 14px;
        line-height: 30px;
        color: $grey;
        font-size: 18px;
    }

    .btn + .btn {
        margin-left: 20px;
    }

    figure {
        height: 282px;
        background: #ededee;
        border-radius: 0 10px 10px 0;
    }

    @include respond-to-max(960px) {
        display: block;
        margin: 38px 20px;

        > div {
            display: block;
            padding: 20px 24px;
            width: auto;
            text-align: center;
        }

        h5 {
            line-height: 30px;
            font-size: 20px;
        }

        p {
            margin: 6px 0 20px;
            line-height: 26px;
            font-size: 16px;
        }

        figure {
            border-radius: 0 0 10px 10px;
            height: 160px;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }

    @include respond-to-max(480px) {
        .btn {
            display: block;
            width: 100%;
        }
        .btn + .btn {
            margin-left: 0;
            margin-top: 12px;
        }
    }
}

.modal-buying-options {
    .modal-content {
        width: 520px;
    }

    h3 {
        margin-top: 0;
        line-height: 30px;
        font-size: 20px;
        padding-right: 40px;
    }

    p {
        font-size: 16px;
        line-height: 26px;
        margin: 26px 0;
    }

    ul {
        margin-top: 40px;

        li {
            border-top: 1px solid $light-grey;
            padding: 14px 0;

            &:last-child {
                padding-bottom: 0;
            }

            display: flex;

            svg {
                width: 32px;
                height: auto;
                margin: 0 24px;
                fill: $brand;

                &.deliver {
                    fill: var(--success);
                }
            }

            h5,
            p {
                margin: 0;
            }

            h5 {
                font-size: 16px;
                margin-bottom: 6px;
                font-weight: var(--medium);
            }

            p {
                font-size: 14px;
            }

            p,
            a {
                color: $grey;
            }

            a {
                text-decoration: underline;
            }
        }
    }
}
