@import '../../shared/settings';

.row-label {
    &.row-label-check {
        display: flex;
        padding: 0 0 0 22px;
        line-height: 24px;
        color: $dark-grey;
        font-weight: normal;
        cursor: pointer;

        a {
            text-decoration: underline;
            color: $dark-grey;
            font-size: inherit;
            font-weight: normal;
        }

        p {
            &:first-of-type {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }

        .checkbox {
            position: absolute;
            top: 4px;
            left: 0;
            margin-right: 6px;
        }
    }
}

.form-row {
    .row-label-check {
        margin-top: 0;
        color: $dark-grey;
        font-size: 16px;
        font-weight: normal;
    }
}
