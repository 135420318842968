@import '../../shared/settings';

.discover-page {
    position: relative;
    overflow: hidden;

    @include respond-to(mobile) {
        margin-top: 0;
        padding-bottom: 38px;
    }

    .inner {
        padding: 0 16px;

        @include respond-to(mobile) {
            padding: 0;
        }

        .title {
            display: flex;
            flex-flow: row wrap;
            margin: 16px 0 18px;

            &.theme {
                a {
                    color: $dark-grey !important;
                }
            }

            h1 {
                flex-grow: 1;
                margin: 0;
                line-height: 32px;
                font-size: 22px;
                font-weight: bold;
            }

            a {
                position: relative;
                top: 6px;
                text-decoration: underline;
                line-height: 26px;
                font-size: 16px;
            }

            @include respond-to(mobile) {
                margin: 38px 20px 14px;

                h1 {
                    line-height: 26px;
                    font-size: 16px;
                }

                a {
                    top: 2px;
                    line-height: 24px;
                    font-size: 14px;
                }
            }
        }

        .grid-block {
            margin-bottom: 54px;
            padding: 16px 0;
            @include respond-to(mobile) { 
                padding: 16px;
            }
        }
    }
}

.search-results-item-invite {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid $light-grey;
    border-radius: 10px;
    padding: 20px;
    height: 100%;
    text-align: center;
    box-sizing: border-box;

    h4 {
        margin: 0 0 6px;
        line-height: 28px;
        font-size: 18px;
        font-weight: var(--medium);
    }

    p {
        margin: 0 0 20px;
        line-height: 26px;
        color: $grey;
        font-size: 16px;
    }

    .btn {
        display: inline-block;
        padding-top: 0;
        height: auto;
    }

    @include respond-to(mobile) {
        border: 0;
        border-radius: 0;
        background-color: rgba($light, 0.35);
        padding: 64px 20px;

        .btn {
            padding-top: 0;
            height: auto;
        }
    }
}