@import '../../shared/settings.scss';

.entrepeneurs-list {
    display: grid;
    margin: 32px 0 54px;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    padding: 0;

    @include respond-to(mobile) {
        margin: 20px;
        grid-template-columns: 1fr;
    }

    li {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        border-radius: 10px;
        padding: 28px;
        height: 294px;
        overflow: hidden;
        text-align: center;
        box-sizing: border-box;

        // overflow-hidden border radius Safari fix
        mask-image: radial-gradient(black, black);

        > a {
            padding: 0;

            &:hover {
                .image-holder {
                    transform: scale(1.02);
                    transition: transform $slow;

                    &::after {
                        transition: opacity $slow;
                        opacity: 0.5;
                    }
                }

                h2,
                h3 {
                    transform: translateY(-2px);
                    transition: transform $slow;
                }
            }
        }

        .image-holder {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transition: transform $slow;

            &::after {
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                transition: opacity $slow;
                opacity: 0.4;
                background: linear-gradient(
                    180deg,
                    rgba($gradient, 0) 2%,
                    $gradient 69%,
                    $gradient 100%
                );
                height: 100%;
                content: ' ';
            }

            figure {
                svg {
                    display: none;
                }
            }
        }

        h2,
        h3 {
            position: relative;
            transition: transform $slow;
            text-shadow: 0 2px 4px rgba($dark, 0.25);
            white-space: normal;
            color: white;
        }

        h2 {
            margin: 0 0 6px;
            line-height: 32px;
            font-size: 22px;
            font-weight: bold;
        }

        h3 {
            line-height: 26px;
            font-size: 16px;
        }
    }
}

.entrepreneur-profile {
    h1 {
        margin: 0 24px 0 0;
        line-height: 36px;
        font-size: 26px;

        & + p {
            font-weight: var(--medium);
        }
    }

    p {
        margin: 24px 0;
        line-height: 28px;
        font-size: 18px;

        &:last-child {
            margin-bottom: 0;
        }

        a {
            text-decoration: underline;

            &:hover {
                cursor: pointer;
            }
        }
    }

    img {
        display: block;
        width: 100%;
    }

    caption {
        display: block;
        margin: 6px 0 0;
        text-align: left;
        line-height: 24px;
        color: $grey;
        font-size: 14px;
        font-weight: var(--medium);
    }

    @include respond-to(mobile) {
        h1 {
            line-height: 32px;
            font-size: 22px;
        }

        p {
            line-height: 26px;
            font-size: 16px;
        }

        caption {
            line-height: 22px;
            font-size: 12px;
        }
    }

    .entrepeneur-share {
        margin: 24px 0 0;
        border-top: 1px solid rgba($light-grey, 0.5);
        padding: 18px 0;
        display: flex;
        align-content: center;
        font-weight: var(--medium);

        div {
            margin: 0 0 0 12px;

            > a {
                margin: 0 6px 0 0;

                &:last-child {
                    margin: 0;
                }
            }
        }
    }

    .entrepeneur-locations {
        border-top: 1px solid rgba($light-grey, 0.5);
        padding: 0;
        list-style: none;

        li {
            padding: 28px 0;
            height: 84px;
            overflow: hidden;
            line-height: 28px;

            a {
                display: grid;
                grid-template-columns: 84px 1fr;
                grid-column-gap: 20px;
            }

            div {
                @extend %nowrap;
            }

            &:last-child {
                padding-bottom: 0;
            }

            figure {
                border-radius: 5px;
                width: 84px;
                min-width: 84px;
                height: 84px;
            }

            h2,
            p,
            .link {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            h2 {
                margin: 0;
                line-height: 26px;
                color: $dark-grey;
                font-size: 18px;
                font-weight: var(--medium);

                @include respond-to(mobile) {
                    margin: 0;
                }
            }

            .link,
            p {
                margin: 3px 0 0;
                line-height: 26px;
                color: $grey;
                font-size: 16px;
            }

            .link {
                display: block;
                text-decoration: underline;
                color: $brand;
                font-weight: normal;
            }
        }

        @include respond-to(mobile) {
            li {
                a {
                    align-items: center;
                    grid-template-columns: 80px 1fr;
                }

                figure {
                    border-radius: 5px;
                    width: 80px;
                    min-width: 80px;
                    height: 80px;
                }

                h2 {
                    line-height: 24px;
                    font-size: 16px;
                }

                .link,
                p {
                    line-height: 24px;
                    font-size: 14px;
                }
            }
        }
    }
}
