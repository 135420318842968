@import '../../shared/settings';
@import '../../backoffice/settings';
@import '../../mixins/scrollbar';



.zupr-dropdown {
    z-index: 101;
    border: 1px solid $light-grey;
    border-radius: 5px;
    box-shadow: -2px 2px 8px 0 rgba(black, 0.25);
    background: white;
    max-width: 480px;

    .zupr-dropdown-overflow {
        @include styledScrollbar;
        max-height: calc(100vh - 280px);
        overflow: auto;
    }

    .dropdown-header {
        border-bottom: 1px solid $light-grey;
        display: flex;
        align-items: center;
        padding: 16px;
        gap: 32px;
        
        &, h3 {
            font-size: var(--systexttitlesmallsize);
        }

        h3 {
            margin: 0;
        }

        small {
            font-size: 12px;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            border-bottom: 1px solid $light-grey;

            &:last-child {
                border-bottom: none;
            }

            a, button {
                display: block;
                padding: 16px;
                display: flex;
                gap: 16px;
                align-items: center;
                font-size: var(--systextbodylargesize);

                &:hover {
                    text-decoration: underline;
                }

                svg {
                    width: 24px;
                    height: 24px;
                }
            }

            button {
                width: 100%;
                text-align: left;
            }
        }
    }
}