@import '../../shared/settings';

.form-error {
    position: relative;
    background: var(--danger);
    padding: 12px 32px;
    text-align: center;
    line-height: 24px;
    color: white;
    font-size: 14px;
    font-weight: var(--medium);
    border-radius: 8px;
    margin: 12px 0;

    a {
        color: white;
        text-decoration: underline;
    }

    svg {
        display: inline-block;
        margin-right: 10px;
        width: 25px;
        height: 24px;
        vertical-align: middle;
        fill: white;
    }
}
