@import '../../../scss/mixins/respond.scss';

.modal-box {
    border-radius: 10px;
    background: white;

    .inner {
        padding: 34px;

        @include respond-to(mobile) {
            padding: 24px;
        }

        &.success {
            background-color: var(--success);
        }

        &.warning {
            background-color: var(--danger);
        }

        &.buttons {
            border: 0;
            border-radius: 0 0 10px 10px;
            background-color: #f9f9fb;
            padding: 0 24px 24px;
        }
    }
}