@import '../../shared/settings.scss';

.usps {
    ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0 0 20px;

        li {
            display: grid;
            margin-right: 32px;
            line-height: 32px;
            grid-template-columns: 32px 1fr;
            grid-column-gap: 8px;

            &:last-child {
                margin-right: 0;
            }

            span {
                display: block;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                border-radius: 16px;
                background-color: rgba($success, 0.08);
                width: 32px;
                height: 32px;
                fill: var(--success);

                svg {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 16px;
                    height: 16px;

                    &.box {
                        width: 14px;
                        height: 14px;
                    }
                }
            }

            p {
                margin: 6px 0;
                line-height: 20px;
                color: $grey;
                font-size: 14px;
                font-weight: var(--medium);
            }
        }
    }

    @include respond-to(mobile) {
        display: none;
    }
}
