@import '../../mixins/respond';
@import '../../mixins/font';
@import '../../shared/settings';

.social-banner {
    display: flex;
    margin: 0 0 54px;
    border: 1px solid $light-grey;
    border-radius: 10px;

    > div {
        display: flex;
        align-items: center;
        padding: 20px 34px;
        width: 100%;
        min-width: calc(50% - 70px);

        & + div {
            border-left: 1px solid $light-grey;
        }
    }

    .social-banner-content {
        flex-grow: 1;
    }

    h5 {
        margin: 0;
        line-height: 28px;
        font-size: 18px;
        font-weight: bold;
    }

    p {
        margin-top: 6px;
        line-height: 26px;
        color: $grey;
        font-size: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .social-banner-buttons {
        margin-left: 34px;
        white-space: nowrap;

        .btn-social {
            margin: 0 0 0 6px;
            width: 32px;
            height: 32px;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    @include respond-to-max(960px) {
        display: block;
        margin: 38px 20px;

        > div {
            display: block;
            padding: 20px 24px;
            width: auto;
            text-align: center;

            & + div {
                border-top: 1px solid $light-grey;
                border-left: 0;
            }
        }

        h5 {
            line-height: 26px;
            font-size: 16px;
        }

        p {
            margin: 6px 0 20px;
            line-height: 24px;
            font-size: 14px;

            &:last-child {
                margin: 6px 0 20px;
            }
        }

        .social-banner-buttons {
            margin: 0;
        }
    }
}
