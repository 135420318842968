@import '../../shared/settings.scss';

.discover-location {
    margin-top: -20px;
    border-bottom: 1px solid $light-grey;
    background-color: white;
    padding: 16px 24px;
    text-align: center;

    .location {
        display: inline-block;
        position: relative;
        border: 1px solid $light-grey;
        border-radius: 24px;
        background-color: rgba($lighter, 0.5);
        padding: 11px 24px 11px 48px;
        font-size: 18px;
        font-weight: var(--medium);

        @include respond-to(mobile) {
            padding: 5px 20px 5px 38px;
            font-size: 15px;
        }

        svg {
            position: absolute;
            top: 13px;
            left: 20px;
            width: 15px;
            height: 20px;
            fill: var(--success);

            @include respond-to(mobile) {
                top: 9px;
                left: 16px;
                width: 12px;
                height: 16px;
            }
        }
    }

    @include respond-to(mobile) {
        margin-top: 0;
        padding: 14px 20px;
    }
}
