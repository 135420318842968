@import '../../shared/settings';

.price-discounted,
.price-striked {
    margin-left: 8px;
}

.price-discounted {
    margin-left: 8px;
    color: var(--danger);
}

.price-striked {
    text-decoration: line-through;
}
